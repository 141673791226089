<template>
  <div class="terminplaner">
    <button class="ctaButton big gerunded blau" @click="show">
      <slot></slot>
    </button>
    <modal
      name="terminplaner"
      :adaptive="true"
      @before-open="beforeOpen"
      height="auto"
      :scrollable="true"
    >
      <div id="medicosearchWidget"></div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "terminplaner",
  props: ["locale"],
  methods: {
    show() {
      this.$modal.show("terminplaner");
    },
    hide() {
      this.$modal.hide("terminplaner");
    },
    beforeOpen() {
      let terminplanerScript = document.createElement("script");
      let lang = "fr";
      if (this.locale != "fr") {
        lang = "de";
      }
      let link =
        "https://www.medicosearch.ch/widget/api/js?key=231d71f2-1784-4931-b411-24e7823937ac&version=2.0.0&container=%23medicosearchWidget&lang=" +
        lang;
      terminplanerScript.setAttribute("src", link);
      document.head.appendChild(terminplanerScript);
    }
  }
};
</script>

<style lang="stylus" scoped>
button.ctaButton.gerunded {
  border-radius: 3rem;
}

#medicosearchWidget {
  height: 70vh;
  overflow: scroll;

  @media screen and (min-width: 768px) {
    height: 50vh;
  }
}
</style>

